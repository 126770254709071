<template>
  <aw-form v-model="v$" @on-submit="onSubmitForm">
    <div aria-live="assertive">
      <aw-notification-item
        v-if="apiError"
        :notification="apiError"
        :thin-content="true"
        index="0"
      >
        <template v-if="userExistsError" #subtitle="{notificationItemStyle}">
          <div :class="[notificationItemStyle.notificationItemContentSubtitle, $style.notificationItemContentSubtitle]">
            <lv-button
              :loading-id="LOGGED_OUT_TOASTER_PROFILE_FORM"
              styling="primary-small"
              shrink
              :class="$style.button"
              @click="openLoginModal(isCareerUser ? {
                loadingId: LOGGED_OUT_TOASTER_PROFILE_FORM,
                showToaster: true,
                toasterAttributes: {
                  title: $awt('aw.career.toast.logged_out_from_career_profile')
                }
              } : {})"
            >
              {{ $awt('aw.common.header.user.login') }}
            </lv-button>
          </div>
        </template>
      </aw-notification-item>
    </div>

    <div :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="prefix.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.prefix') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2
          v-model="prefix"
          stretch
          :show-form-row-label="false"
          :class="$style.fieldPrefix"
        />
      </div>
    </div>
    <div :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="lastName.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.last_name') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2
          v-model="lastName"
          :show-form-row-label="showFormRowLabel"
          :class="$style.fieldLastName"
        />
      </div>
    </div>
    <div :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="firstName.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.first_name') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2
          v-model="firstName"
          :show-form-row-label="showFormRowLabel"
          :class="$style.fieldFirstName"
        />
      </div>
    </div>

    <div v-if="enableBirthDateField" :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="birthDate.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.birth_date') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2
          v-model="birthDate"
          :show-form-row-label="showFormRowLabel"
          :class="$style.fieldBirthDate"
        />
      </div>
    </div>

    <div v-if="!formData.isB2B" :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="gender.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.gender_label') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2
          v-model="gender"
          stretch
          :show-form-row-label="false"
          :class="$style.fieldGender"
        />
      </div>
    </div>

    <div :class="$style.dataRow">
      <label v-if="!showFormRowLabel" :for="phone.widgetAttrs.id" :class="$style.dataName">
        {{ $awt('aw.bandd.phone_number') }}
      </label>
      <div :class="[$style.dataField, $style.inputFieldLabel]">
        <aw-form-row-version2 v-model="phone" :show-form-row-label="showFormRowLabel" :class="$style.fieldPhone" />
      </div>
    </div>

    <div :class="$style.dataRow">
      <fieldset class="awFieldsetContents">
        <legend :class="[{awSrOnly: screenRange['mobile-max']}, $style.dataName]">
          {{ $awt('aw.bandd.newsletter') }}
        </legend>
        <div :class="[$style.dataField, $style.inputFieldLabel]">
          <aw-newsletter-form
            ref="newsletterForm"
            tag="div"
            :newsletter-subscription-id="newsletterSubscriptionId"
            :edm-subscription-id="edmSubscriptionId"
            :optin-subscription-id="optinSubscriptionId"
            @edm-change="newsletterEdmChange"
          />
        </div>
      </fieldset>
    </div>

    <template v-if="formData.isB2B">
      <div :class="$style.dataRow">
        <label v-if="!showFormRowLabel" :for="companyName.widgetAttrs.id" :class="$style.dataName">
          {{ $awt('aw.bandd.company_name') }}
        </label>
        <div :class="[$style.dataField, $style.inputFieldLabel]">
          <aw-form-row-version2
            v-model="companyName"
            :show-form-row-label="showFormRowLabel"
            :class="$style.fieldCompanyName"
          />
        </div>
      </div>

      <div :class="$style.dataRow">
        <label v-if="!showFormRowLabel" :for="selectedBillingIdentifier.widgetAttrs.id" :class="$style.dataName">
          {{ $awt('aw.bandd.selected_billing_identifier.title') }}
        </label>
        <div :class="[$style.dataField, $style.inputFieldLabel]">
          <aw-form-row-version2 v-model="selectedBillingIdentifier" :show-form-row-label="false" />
        </div>
      </div>

      <div :class="$style.dataRow">
        <label
          v-if="!showFormRowLabel"
          :for="isTaxNumberSelected ? taxNumber.widgetAttrs.id : groupIdNumber.widgetAttrs.id"
          :class="$style.dataName"
        >
          {{ $awt(isTaxNumberSelected ? 'aw.bandd.tax_number' : 'aw.bandd.group_id_number') }}
        </label>
        <div :class="[$style.dataField, $style.inputFieldLabel]">
          <aw-form-row-version2
            v-if="isTaxNumberSelected"
            v-model="taxNumber"
            :show-form-row-label="showFormRowLabel"
          />
          <aw-form-row-version2
            v-if="isGroupIdNumberSelected"
            v-model.number="groupIdNumber"
            :show-form-row-label="showFormRowLabel"
          />
        </div>
      </div>
    </template>
  </aw-form>
</template>

<script>
  import { defineAsyncComponent, nextTick } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { required, email, requiredIf } from '@vuelidate/validators';
  import { mapState } from 'pinia';
  import { useRuntimeConfig, useNuxtApp } from 'nuxt/app';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { removeTZInfoFromDateStr } from '~~/common/utils';
  import AwForm from '~~/common/components/Form/AwForm';
  import AwFormRowVersion2 from '~~/common/components/Form/AwFormRowVersion2';
  import { backendValidator, getErrorNotification, setValidationFromError } from '~~/common/utils/helper';
  import { BI_VAL_OTHER } from '~~/common/config/BillingConfig.js';
  import userLoginAndRegistrationMixin from '~~/common/mixins/userLoginAndRegistrationMixin';
  import { INPUT_LABEL_PLACE_OUTER, INPUT_SIZE } from '~/awPlugins/app-config';
  import AwNewsletterForm from '~~/shop/components/Page/Profile/Forms/AwNewsletterForm';

  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useCheckoutStore } from '~~/common/stores/checkout';
  import { useUserStore } from '~~/common/stores/user';
  import { LOGGED_OUT_TOASTER_PROFILE_FORM } from '~~/common/config/LoadingIdConfig.js';

  export default {
    name: 'AwProfileDataFormVersion2',
    components: {
      AwNewsletterForm,
      AwNotificationItem: defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem')),
      LvButton,
      AwFormRowVersion2,
      AwForm,
    },
    mixins: [userLoginAndRegistrationMixin],
    props: {
      noPhoneRequired: {
        type: Boolean,
        default: false,
      },
      formData: {
        type: Object,
        default: () => ({}),
      },
      sectionCardStyle: {
        type: Object,
        default: () => ({}),
      },
      showCancelButton: {
        type: Boolean,
        default: true,
      },
      showSaveButton: {
        type: Boolean,
        default: true,
      },
      isGuestCheckout: {
        type: Boolean,
        default: false,
      },
      newsletterSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
      edmSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
      optinSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
      enableBirthDateField: {
        type: Boolean,
        required: true,
      },
      enableBirthDateFieldEdit: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['validation-change', 'section-edit'],
    setup: () => ({ v$: useVuelidate() }),
    validations () {
      const userInterfaceStore = useUserInterfaceStore();
      const config = useRuntimeConfig();
      return {
        email: {
          model: {
            required,
            email,
            backend: backendValidator(this),
          },
        },
        confirmEmail: {
          model: {
            required: requiredIf(function () {
              return this.isGuestCheckout;
            }),
            email,
            backend: backendValidator(this),
            sameAsEmail (value) {
              return this.isGuestCheckout ? this.email.model === value : true;
            },
          },
        },
        firstName: {
          model: {
            required,
            backend: backendValidator(this),
          },
        },
        lastName: {
          model: {
            required,
            backend: backendValidator(this),
          },
        },
        companyName: {
          model: {
            required: requiredIf(function () {
              return this.formData.isB2B;
            }),
          },
        },
        phone: {
          model: {
            phoneValidator (phoneNumber) {
              return (phoneNumber[1] || phoneNumber[2]) ? userInterfaceStore.phoneValidator(phoneNumber) : this.noPhoneRequired && !this.edmEnabled;
            },
            backend: backendValidator(this),
          },
        },
        prefix: {
          model: {
            backend: backendValidator(this),
          },
        },
        birthDate: {
          model: {
            required: requiredIf(function () {
              return this.enableBirthDateField;
            }),
            isValid (date) {
              return this.enableBirthDateField ? userInterfaceStore.isValidDate(date) : true;
            },
            isOver18 (date) {
              return this.enableBirthDateField ? userInterfaceStore.isOver18(date) : true;
            },
          },
        },
        selectedBillingIdentifier: {
          model: {
            sbi (selectedBillingIdentifier) {
              return Boolean(!config.public.isBillingCategoryAllowed || !this.formData.isB2B || selectedBillingIdentifier.id);
            },
          },
        },
        taxNumber: {
          model: {
            taxNumberValidator (value) {
              const r = this.formData.isB2B && value?.length && (!config.public.isBillingCategoryAllowed || this.isTaxNumberSelected);
              return Boolean(!r || userInterfaceStore.taxNumberValidator(value, config.public));
            },
          },
        },
        groupIdNumber: {
          model: {
            groupIdNumberValidator (value) {
              const r = this.formData.isB2B && value?.length && this.isGroupIdNumberSelected;
              return Boolean(!r || userInterfaceStore.groupIdNumberValidator(value));
            },
          },
        },
      };
    },
    data () {
      const userInterfaceStore = useUserInterfaceStore();
      const groupIdNumberValidator = userInterfaceStore.getValidator('group_id_number');

      const defaultBirthDate = new Date();
      defaultBirthDate.setFullYear(defaultBirthDate.getFullYear() - 18);

      return {
        edmEnabled: false,
        apiError: false,
        userExistsError: false,
        backendValidation: {
          errors: {},
          propertyPaths: {
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            prefix: 'title',
            gender: 'gender',
            confirmEmail: 'confirmEmail',
          },
        },
        prefix: {
          model: '',
          options: [],
          inlineLabelText: this.$awt('aw.bandd.prefix'),
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          dropdownIconAttrs: {
            name: 'arrow-down-16',
            size: 10,
          },
          multiple: false,
          type: 'select',
          name: 'prefix',
          labelAttrs: {
            for: 'prefix',
          },
          widgetAttrs: {
            id: 'prefix',
          },
        },
        gender: {
          model: '',
          options: [],
          inlineLabelText: this.$awt('aw.bandd.gender'),
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          dropdownIconAttrs: {
            name: 'arrow-down-16',
            size: 10,
          },
          multiple: false,
          type: 'select',
          name: 'gender',
          labelAttrs: {
            for: 'gender',
          },
          widgetAttrs: {
            id: 'gender',
          },
        },
        email: {
          model: '',
          type: 'text',
          name: 'email',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.email'),
          labelAttrs: {
            for: 'email',
          },
          widgetAttrs: {
            id: 'email',
            disabled: !this.isGuestCheckout,
          },
        },
        confirmEmail: {
          model: '',
          type: 'text',
          name: 'confirmEmail',
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.confirm_email'),
          labelAttrs: {
            for: 'confirmEmail',
          },
          widgetAttrs: {
            id: 'confirmEmail',
            disabled: !this.isGuestCheckout,
          },
        },
        firstName: {
          model: '',
          type: 'text',
          name: 'firstName',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.first_name'),
          labelAttrs: {
            for: 'first_name',
          },
          widgetAttrs: {
            id: 'first_name',
          },
        },
        lastName: {
          model: '',
          type: 'text',
          name: 'lastName',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.last_name'),
          labelAttrs: {
            for: 'last_name',
          },
          widgetAttrs: {
            id: 'last_name',
          },
        },
        companyName: {
          model: '',
          type: 'text',
          name: 'companyName',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.company_name'),
          labelAttrs: {
            for: 'company_name',
          },
          widgetAttrs: {
            id: 'company_name',
          },
        },
        phone: {
          model: ['', '', ''],
          type: 'tel',
          name: 'phone',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          labelAfter: this.$awt('aw.bandd.phone_number_example'),
          label: '',
          labelAttrs: {
            for: 'phone',
          },
          widgetAttrs: {
            id: 'phone',
          },
          tokens: {},
        },
        birthDate: {
          model: '',
          type: 'date',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          name: 'birthDate',
          label: this.$awt('aw.bandd.birth_date'),
          labelAttrs: {
            for: 'birthDate',
          },
          widgetAttrs: {
            id: 'birthDate',
            disabled: true,
          },
          tokens: {
            isValid: 'aw.common.form.date_not_valid',
            isOver18: 'aw.common.form.over18_error_msg',
          },
          defaultFromDate: defaultBirthDate,
          datePickerConfig: {
            maxDate: defaultBirthDate,
          },
        },
        selectedBillingIdentifier: {
          model: {
            name: this.$awt('aw.bandd.selected_billing_identifier.options.tax_number'),
            id: 'tax_number',
          },
          inlineLabelText: this.$awt('aw.bandd.selected_billing_identifier.title'),
          multiple: false,
          options: [
            {
              name: this.$awt('aw.bandd.selected_billing_identifier.options.tax_number'),
              id: 'tax_number',
            },
            {
              name: this.$awt('aw.bandd.selected_billing_identifier.options.group_id_number'),
              id: 'group_id_number',
            },
          ],
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          type: 'select',
          name: 'selectedBillingIdentifier',
          labelAttrs: {
            for: 'selected_billing_identifier',
          },
          widgetAttrs: {
            id: 'selected_billing_identifier',
          },
          dropdownIconAttrs: {
            name: 'arrow-down-16',
            size: 10,
          },
          tokens: {
            sbi: 'aw.common.form.validation.required',
          },
        },
        taxNumber: {
          model: '',
          type: 'text',
          name: 'taxNumber',
          label: this.$awt('aw.bandd.tax_number'),
          specialType: 'tax',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          labelAttrs: {
            for: 'tax_number',
            showAsterisk: false,
          },
          widgetAttrs: {
            id: 'tax_number',
            maxlength: userInterfaceStore.taxNumberInputLength,
          },
          tokens: {
            taxNumberValidator: userInterfaceStore.getValidator('tax_number').instruction,
          },
        },
        groupIdNumber: {
          model: '',
          type: 'text',
          name: 'groupIdNumber',
          version2: true,
          inputSize: INPUT_SIZE, // aw-form-row <label> tag size
          outerLabel: INPUT_LABEL_PLACE_OUTER,
          label: this.$awt('aw.bandd.group_id_number'),
          labelAttrs: {
            for: 'group_id_number',
            showAsterisk: false,
          },
          widgetAttrs: {
            id: 'group_id_number',
            maxlength: groupIdNumberValidator.metaData.length,
          },
          widgetProps: {
            prefix: groupIdNumberValidator.metaData.prefix,
          },
          tokens: {
            groupIdNumberValidator: groupIdNumberValidator.instruction,
          },
        },
        clearFormTimeout: null,
        LOGGED_OUT_TOASTER_PROFILE_FORM,
      };
    },
    computed: {
      ...mapState(useUserStore, {
        isCareerUser: state => state.isCareerUser,
      }),
      ...mapState(useCheckoutStore, {
        checkoutType: state => state.checkoutType,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
        validator: state => state.getValidator('phone_number'),
        userPrefixes (state) {
          const defaultVal = [{
            id: 0,
            name: this.$awt('aw.bandd.prefix'),
            title: true,
          }];
          const backendVal = state.getValidator('user_title')?.metaData?.userTitles || [];
          return [...defaultVal, ...backendVal.map(e => ({
            id: e,
            name: e,
          }))];
        },
        genderOptions (state) {
          const defaultVal = [{
            id: 0,
            name: this.$awt('aw.bandd.gender'),
            title: true,
          }];
          const backendVal = state.getValidator('user_gender')?.metaData?.userGenders || {};
          return defaultVal.concat(Object.entries(backendVal).map(([id, name]) => ({
            id,
            name,
          })));
        },
      }),
      showFormRowLabel () {
        return this.screenRange['mobile-max'];
      },
      isTaxNumberSelected () {
        return this.selectedBillingIdentifier.model.id === 'tax_number';
      },
      isGroupIdNumberSelected () {
        return this.selectedBillingIdentifier.model.id === 'group_id_number';
      },
    },
    watch: {
      v$: {
        handler () {
          if (!this.isGuestCheckout) {
            this.v$.$touch();
            this.$emit('validation-change', this.v$);
          }
        },
        deep: true,
      },
      'backendValidation.errors': {
        deep: true,
        handler (n) {
          clearTimeout(this.clearFormTimeout);
          if (Object.keys(n).length) {
            this.clearFormTimeout = setTimeout(() => {
              this.v$.$reset();
              this.backendValidation.errors = {};
            }, 3000);
          }
        },
      },
    },
    mounted () {
      if (this.enableBirthDateFieldEdit) {
        this.birthDate.widgetAttrs.disabled = false;
        this.birthDate.widgetIconAttrs = {
          size: 16,
          name: 'calendar-version2-16',
          position: 'after',
          'data-toggle': true,
        };
      }
      nextTick(() => {
        this.initForm();
        if (this.isGuestCheckout) {
          this.v$.$reset();
        }
      });
    },
    methods: {
      newsletterEdmChange (event) {
        this.edmEnabled = event.model;
        this.v$.$touch();
      },
      initForm () {
        const config = useRuntimeConfig();
        const userInterfaceStore = useUserInterfaceStore();
        this.prefix.options = this.userPrefixes;
        this.prefix.model = this.formData?.title
          ? {
            id: this.formData.title,
            name: this.formData.title,
          }
          : this.userPrefixes[0]
        ;

        this.gender.options = this.genderOptions;
        {
          const selectedId = parseInt(this.formData.gender, 10);
          this.gender.model = this.genderOptions.find(g => parseInt(g.id, 10) === selectedId) || this.genderOptions[0];
        }

        this.firstName.model = this.formData?.firstName;
        this.lastName.model = this.formData?.lastName;
        this.companyName.model = this.formData?.companyName;
        this.email.model = this.formData?.email;

        if (this.isGuestCheckout) {
          this.confirmEmail.model = this.formData?.confirmEmail;
        }

        this.phone.tokens.phoneValidator = userInterfaceStore.getValidator('phone_number')?.instruction;
        this.phone.model = userInterfaceStore.str2Phone(this.formData?.phoneNumber || '', true);

        this.birthDate.model = removeTZInfoFromDateStr(this.formData.birthDate);
        {
          const isOtherType = this.formData?.billingIdentifier === BI_VAL_OTHER;
          const shortenedGIN = this.formData?.billingIdentifier?.slice(this.groupIdNumber.widgetProps.prefix.length) || '';
          const isTax = !config.public.isBillingCategoryAllowed || (userInterfaceStore.taxNumberValidator(this.formData?.billingIdentifier, config.public) && !userInterfaceStore.groupIdNumberValidator(shortenedGIN));
          if (isTax) {
            this.taxNumber.model = isOtherType ? '' : this.formData.billingIdentifier;
          } else {
            this.groupIdNumber.model = isOtherType ? '' : shortenedGIN;
          }
          this.selectedBillingIdentifier.model = this.selectedBillingIdentifier.options.find(o => o.id === (isTax ? 'tax_number' : 'group_id_number'));
        }

        this.edmEnabled = this.edmSubscriptionId !== null;
      },
      async validateForm () {
        this.v$.$touch();
        if (!this.$refs?.newsletterForm) {
          await nextTick();
        }
        this.$refs.newsletterForm.v$.$touch();
        return !this.v$.$invalid && !this.$refs.newsletterForm.v$.$invalid;
      },
      async onSubmitForm () {
        const userInterfaceStore = useUserInterfaceStore();
        const userStore = useUserStore();
        try {
          if (this.isGuestCheckout) {
            return this.onSubmitGuestCheckoutForm();
          } else if (!await this.validateForm()) {
            return false;
          }

          userInterfaceStore.startLoading({ id: 'profile-form-save' });

          // initial the this.birthDate.model is ISOString and don't have toISOString function
          const birthDate = this.enableBirthDateField
            ? (this.birthDate.model.toISOString
              ? `${this.birthDate.model.getFullYear()}-${this.birthDate.model.getMonth().toString().padStart(2, 0)}-${this.birthDate.model.getDate().toString().padStart(2, 0)}`
              : removeTZInfoFromDateStr(this.birthDate.model)
            )
            : this.formData.birthDate;

          await this.$api.$put('/me/personal_info', {
            title: this.prefix.model.id || '',
            gender: parseInt(this.gender.model.id) || null,
            firstName: this.firstName.model,
            lastName: this.lastName.model,
            companyName: this.companyName.model,
            billingIdentifier: (
              (this.isTaxNumberSelected
                ? this.taxNumber.model
                : (this.groupIdNumber.model
                  ? this.groupIdNumber.widgetProps.prefix + this.groupIdNumber.model
                  : ''
                )
              ) || (this.formData.isB2B ? BI_VAL_OTHER : '')
            ),
            email: this.email.model,
            phoneNumber: this.phone.model.length === 3 && this.phone.model[2] !== '' ? this.phone.model.join('') : '',
            birthDate,
          });
          await this.$refs.newsletterForm.onSubmitNewsletterData();
          await userStore.fetchUser();

          this.apiError = false;
          userInterfaceStore.endLoading({ id: 'profile-form-save' });

          return true;
        } catch (error) {
          setValidationFromError(this, error, useNuxtApp());
          this.apiError = getErrorNotification(useNuxtApp(), error);
          userInterfaceStore.endLoading({ id: 'profile-form-save' });

          return false;
        }
      },
      async onSubmitGuestCheckoutForm () {
        const userInterfaceStore = useUserInterfaceStore();
        const checkoutStore = useCheckoutStore();
        try {
          if (!await this.validateForm()) {
            return false;
          }
          userInterfaceStore.startLoading({ id: 'profile-form-save' });

          // initial the this.birthDate.model is ISOString and don't have toISOString function
          const birthDate = this.enableBirthDateField
            ? (this.birthDate.model.toISOString
              ? this.birthDate.model.toISOString()
              : this.birthDate.model)
            : this.formData.birthDate;

          const result = await checkoutStore.updateCheckoutData({
            data: {
              customer: {
                title: this.prefix.model.value || '',
                firstName: this.firstName.model,
                lastName: this.lastName.model,
                email: this.email.model,
                confirmEmail: this.confirmEmail.model,
                phoneNumber: this.phone.model.length === 3 && this.phone.model[2] !== '' ? this.phone.model.join('') : '',
                birthDate,
              },
            },
            type: this.checkoutType,
          });
          this.apiError = false;
          this.userExistsError = false;
          if (!result.status) {
            if (result.error.response.data.message.includes('user_exists')) {
              result.error.response.data.message = this.$awt('aw.guest_checkout.user_exists');
              this.userExistsError = true;
            }

            setValidationFromError(this, result.error, useNuxtApp());
          } else {
            this.emitEditEnd();
          }

          return result;
        } catch (error) {
          setValidationFromError(this, error, useNuxtApp());
          this.apiError = getErrorNotification(useNuxtApp(), error);
        } finally {
          userInterfaceStore.endLoading({ id: 'profile-form-save' });
        }
      },

      emitEditEnd () {
        this.$emit('section-edit', false);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.data {
  &Table {
    display: flex;
    flex-direction: column;
  }

  &Row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    @include tablet(min) {
      column-gap: 24px;
      grid-template-columns: 240px 1fr;
    }
    @include desktop-small(only) {
      column-gap: 32px;
    }
    @include desktop-medium(min) {
      column-gap: 40px;
    }
  }

  &Name {
    font-size: 16px;
    font-weight: $font-weight-normal-v2;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    padding-top: 14px;

    &Checkbox {
      padding-top: 0;
    }
  }

  &Field {
    display: flex;
    align-items: center;
  }
}

.field {
  &Prefix [data-form-widget] {
    [data-form-input-select] {
      width: 152px;
    }

    [data-form-input-dropdown] {
      min-width: 152px;
    }
  }

  &Gender [data-form-widget] {
    [data-form-input-select] {
      width: 152px;
    }

    [data-form-input-dropdown] {
      min-width: 152px;
    }
  }

  &FirstName [data-form-widget] {
    width: 200px;
  }

  &LastName [data-form-widget] {
    width: 200px;
  }

  &BirthDate [data-form-widget] {
    width: 152px;
  }

  &Phone [data-form-widget] {
    width: 152px;
  }
}
</style>
