<template>
  <aw-form v-model="v$" :tag="tag" @on-submit="onSubmitNewsletterData">
    <aw-form-row-version2 v-model="newsletter" />
    <aw-form-row-version2 v-model="edm" />
    <aw-form-row-version2 v-model="optin" />
  </aw-form>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import AwForm from '~~/common/components/Form/AwForm';
  import { PUSH_NEWSLETTER } from '~~/common/plugins/aw-analytics.js';
  import AwFormRowVersion2 from '~~/common/components/Form/AwFormRowVersion2.vue';

  export default {
    name: 'AwNewsletterForm',
    components: {
      AwForm,
      AwFormRowVersion2,
    },
    props: {
      tag: {
        type: String,
        default: 'form',
        validator: v => ['form', 'div'].includes(v),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      newsletterSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
      edmSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
      optinSubscriptionId: {
        validator: prop => typeof prop === 'number' || prop === null,
        required: true,
      },
    },
    emits: ['edm-change'],
    setup: () => ({ v$: useVuelidate() }),
    validations () {
      return {};
    },
    data () {
      return {
        newsletter: {
          model: this.newsletterSubscriptionId !== null,
          type: 'checkbox',
          name: 'newsletter',
          widgetProps: {
            label: this.$awt('aw.common.newsletter.checkbox_label'),
            alignItems: 'start',
            showLabelInDesktop: true,
            disabled: this.disabled,
          },
          version2: true,
        },
        edm: {
          model: this.edmSubscriptionId !== null,
          type: 'checkbox',
          name: 'edm',
          widgetProps: {
            label: this.$awt('aw.common.edm_newsletter.checkbox_label'),
            alignItems: 'start',
            showLabelInDesktop: true,
            disabled: this.disabled,
          },
          version2: true,
        },
        optin: {
          model: this.optinSubscriptionId !== null,
          type: 'checkbox',
          name: 'optin',
          widgetProps: {
            label: this.$awt('aw.common.optin_newsletter.checkbox_label'),
            alignItems: 'start',
            showLabelInDesktop: true,
            disabled: this.disabled,
          },
          version2: true,
        },
      };
    },
    watch: {
      edm: {
        handler (newValue, oldValue) {
          if (newValue.model !== oldValue.model) {
            this.$emit('edm-change', newValue);
          }
        },
      },
      newsletterSubscriptionId: {
        handler (value) {
          this.newsletter.model = value !== null;
        },
        deep: true,
      },
      edmSubscriptionId: {
        handler (value) {
          this.edm.model = value !== null;
        },
        deep: true,
      },
      optinSubscriptionId: {
        handler (value) {
          this.optin.model = value !== null;
        },
        deep: true,
      },
    },

    methods: {
      async onSubmitNewsletterData () {
        try {
          const newsLetterRequest = [
            {
              subscriptionType: 'newsletter',
              isSubscribed: this.newsletter.model,
            },
            {
              subscriptionType: 'edm',
              isSubscribed: this.edm.model,
            },
            {
              subscriptionType: 'profiling',
              isSubscribed: this.optin.model,
            },
          ];
          await this.$api.put('/me/subscriptions/all', { items: newsLetterRequest });
          if (this.newsletter.model && !this.newsletterSubscriptionId) {
            this.$awAnalytics[PUSH_NEWSLETTER]();
          }
          return true;
        } catch (err) {
          this.$logger.error(err);
          return false;
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
</style>
